export default function newBlog() {
    return [
        {
            "source": {
            "id": "cnn",
            "name": "CNN"
            },
            "author": "Natasha Chen",
            "title": "Knights, queens and strippers are helping fuel the spike in US unionizing efforts",
            "description": "US strippers haven't successfully unionized since the effort at San Francisco's Lusty Lady in the 1990s, but the dancers at LA's Star Garden club are trying anyway — in an environment where more American workers are sensing their own power.",
            "url": "https://www.cnn.com/2022/08/06/business/strippers-knights-unions-trend/index.html",
            "urlToImage": "https://media.cnn.com/api/v1/images/stellar/prod/220805131422-restricted-01-star-garden-bar-dancers-protest.jpg?c=16x9&q=w_800,c_fill",
            "publishedAt": "2022-08-06T10:58:43Z",
            "content": "Los AngelesCNN Business\r\n  — \r\nOn any given weekend night in North Hollywood, a group of dancers in outlandish costumes holds signs urging customers to stay away from the Star Garden topless bar.\r\nMo… [+9016 chars]"
        },
        {
            "source": {
            "id": "cnn",
            "name": "CNN"
            },
            "author": "By Forrest Brown, CNN",
            "title": "What should I do if my luggage is delayed, lost or damaged?",
            "description": "The prospect of airlines losing luggage looms larger than ever. Here's what to do to help prevent your baggage from being delayed or lost -- and what to do if it's gone forever.",
            "url": "https://www.cnn.com/travel/article/lost-delayed-damaged-luggage-baggage-what-to-do/index.html",
            "urlToImage": "https://cdn.cnn.com/cnnnext/dam/assets/190723202528-luggage-carousel-super-tease.jpg",
            "publishedAt": "2022-08-06T18:37:50Z",
            "content": "(CNN) It's enough to give anyone already nervous about the chaos in the skies yet another reason to pop an antacid: the prospect of delayed, lost or damaged baggage.\r\nHanding over checked suitcases c… [+8192 chars]"
        },
        {
            "source": {
            "id": "ars-technica",
            "name": "Ars Technica"
            },
            "author": "Jeff Dunn",
            "title": "The weekend’s best deals: Amazon Kindle Paperwhite, 8BitDo gamepads, and more",
            "description": "Dealmaster also has discounts on the Apple Pencil, PS5 games, and Beats earbuds.",
            "url": "https://arstechnica.com/staff/2022/08/the-weekends-best-deals-amazon-kindle-paperwhite-8bitdo-gamepads-and-more/",
            "urlToImage": "https://cdn.arstechnica.net/wp-content/uploads/2022/08/dealmaster080622-760x380.jpg",
            "publishedAt": "2022-08-06T17:00:16Z",
            "content": "0 with 0 posters participating\r\nIt's the weekend, which means the time has come for another Dealmaster. Our latest roundup of the best tech deals from around the web includes the lowest price we've t… [+10657 chars]"
        }
    ]
}
import React, { Fragment, useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import Modal from "../Modal";
import * as logos from "../../../Assets/img/logos"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import "./header.scss"
import { toastSuccess, toastError  } from "../index";
import { useSignupMutation, useLoginMutation } from '../../../Service/services'


const PublicHeader = () => {
    const [isConfirmationModal, setConfirmationModal] = useState(false)
    const hasJoin = useState(localStorage.getItem('user-data') !== null ? JSON.parse(localStorage.getItem('user-data')) : null) 
    let navigate  = useNavigate()

    let [signupAccount] = useSignupMutation()
    let [loginAccount] = useLoginMutation()

    let handleLoginSuccess = () => {
      handleModalOk()
      // window.location.reload()
      navigate('/dashboard/account')
    };


    let handleSignupSuccess = () => {
      handleModalOk()
    };

    let getData = (data) => {
      if(data.state === 'Create an Account') {
        if((data.username || data.email || data.password) === undefined) {
          return toastError("Please enter all fields.")
        }
        signupAccount({
          username: data.username,
          email: data.email,
          password: data.password,
          image: '',
          type: "users"
        })
        .then((success) => {
          toastSuccess("Signup successfull")
          handleSignupSuccess()
        })
        .catch((error) => {
          console.log('signup error :: ', error)
        })
      } else if(data.state === 'Login') {
        if((data.email || data.password) === undefined) {
          return toastError("Please enter email & password.")
        }
        loginAccount({
          email: data.email,
          password: data.password
        })
        .then((success) => {
          if(success.error !== undefined) {
            return toastError(success.error.data.message)
          }
          document.cookie = "isLogin=true"
          localStorage.setItem('user-data', JSON.stringify(success));
          toastSuccess("Login successfull")
          handleLoginSuccess()
        })
        .catch((error) => {
          console.log('signup error :: ', error)
        })
      } 
    }

    
    // cookies setup
    // const getCookie = (name) => {
    //   var nameEQ = name + "=";
    //   var ca = document.cookie.split(';');
    //   for(var i=0;i < ca.length;i++) {
    //     var c = ca[i];
    //     while (c.charAt(0)===' ') c = c.substring(1,c.length);
    //     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    //   }
    //   return true;
    // }

    // set cookies
    let setCookies = () => {
      return document.cookie = "isLogin=true"
    }

    let handleModal = () => {
      setConfirmationModal(true)
      setCookies()
    }

    // model management
    let handleModalOk = () => setConfirmationModal(false)
    // let handleModalCancel = () => setConfirmationModal(false)


    /*********************
     * @handle logout
     ********************/
    let handleLogout = () => {
      localStorage.removeItem('user-data')
      navigate('/')
      window.location.reload()
    }


    let afterLogin = () => {
        return (
          <Fragment>
            <NavDropdown title={`Reader`} id="collasible-nav-dropdown" style={{paddingRight: "35px", backgroundColor: "#F8F9FA", borderRadius: "5px"}}>
              <NavDropdown.Item as={Link} to="/dashboard/account" className="fs-15 mb-03">
                <FontAwesomeIcon className="bi pe-none me-2" icon={faUser} />
                Profile
              </NavDropdown.Item>
              
              <NavDropdown.Item className="fs-15" onClick={() => handleLogout()}>
                <FontAwesomeIcon className="bi pe-none me-2" icon={faLock} />
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Fragment>
        )
    } 


    return (
        <Fragment>
            {
              hasJoin === null ?
                <div className="nav-top">
                  <b>&#127881; NEW: </b>
                  Turn customer reviews into yours #1 sales asset with a Wall of Love. 
                </div>
              : 
                null
            }
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="main-navigation">
              <Container>
                <Navbar.Brand as={Link} to="/">
                  <img
                    src={logos.revermonk_logo}
                    height="45"
                    width="45"
                    alt="Revermonk"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mx-auto">
                    <Nav.Link as={Link} to="/stories">Stories</Nav.Link>
                    <Nav.Link as={Link} to="/membership">Membership</Nav.Link>
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                    <Nav.Link as={Link} to="/events">Events</Nav.Link>
                    <Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link>
                  </Nav>
                  <Nav className="mx-auto">
                    { hasJoin !== null ? hasJoin[0]?.data?.statusCode === 200 ?
                        afterLogin()
                    :
                      <Nav.Link as={Link} className="btn btn-sm btn-membership-header text-nowrap mb-0 me-1"  onClick={() => handleModal()}>Share your story</Nav.Link>
                    : 
                      <Nav.Link as={Link} className="btn btn-sm btn-membership-header text-nowrap mb-0 me-1"  onClick={() => handleModal()}>Share your story</Nav.Link>
                    }

                  </Nav>
                  {/* Add your conditional rendering here */}
                </Navbar.Collapse>
              </Container>
            </Navbar>

            {isConfirmationModal && (
                <Modal
                    headerTitle={`Be Part of Something Bigger: Join Our Community and Explore New Ideas.`}
                    footerContent={`Click “Sign Up” to agree to Revermonk Terms of Service and acknowledge that Revermonk Privacy Policy applies to you.`}
                    getData={getData}
                    cancel={setConfirmationModal}
                />
            )}
        </Fragment>
    )
} 


export default PublicHeader
import * as imgs from "../Assets/img/illustrations"

export default function newFeed() {
    return [
        {
            "source": {
            "id": "associated-press",
            "name": "Associated Press"
            },
            "author": "Dee-ann Durbin",
            "title": "Demand for grocery delivery cools as food costs rise - The Associated Press - en Español",
            "description": "Karen Raschke, a retired attorney in New York, started getting her groceries delivered early in the pandemic. Each delivery cost $30 in fees and tips.",
            "url": "https://apnews.com/article/grocery-delivery-service-demands-fall-d22c5424c235386ead5f344009540c4b",
            "urlToImage": imgs.news3,
            "publishedAt": "2024-01-07T13:30:00Z",
            "content": "Karen Raschke, a retired attorney in New York, started getting her groceries delivered early in the pandemic. Each delivery cost $30 in fees and tips, but it was worth it to avoid the store.\r\nThen ea… [+5979 chars]"
        },
        {
            "source": {
            "id": null,
            "name": "The Denver Post"
            },
            "author": "Bruce Finley",
            "title": "In Colorado mountain towns, where affordable housing is scarce",
            "description": "Blocked from sleeping in vehicles within municipal boundaries, workers in profit-minded Colorado mountain towns now must seek “safe outdoor space”",
            "url": "https://www.denverpost.com/2022/08/07/colorado-mountain-town-housing-safe-parking-salida/",
            "urlToImage": "https://www.denverpost.com/wp-content/uploads/2022/08/TDP-L-workforce-Salida-RJS-1951.jpg?w=1024&h=683",
            "publishedAt": "2024-02-03T12:00:31Z",
            "content": "SALIDA — Blocked from sleeping in vehicles parked within municipal boundaries, workers in profit-minded Colorado mountain towns now must seek safe outdoor space — in Walmart lots, forests or newly de… [+13809 chars]"
        },
        {
            "source": {
            "id": null,
            "name": "CNBC"
            },
            "author": "Amelia Lucas",
            "title": "Some consumers are cutting back on restaurant spending",
            "description": "Bloomin' Brands, Restaurant Brands International and Starbucks said they aren't seeing changes in consumer spending.",
            "url": "https://www.cnbc.com/2022/08/07/consumers-are-cutting-back-on-restaurant-spending-but-ceos-say-not-all-chains-are-affected.html",
            "urlToImage": imgs.news1,
            "publishedAt": "2024-03-19T12:00:01Z",
            "content": "Some restaurants are reporting weaker sales or declining traffic in the second quarter, signaling that diners are cutting back on eating out to save money.\r\nBut CEOs are split on how consumer behavio… [+3941 chars]"
        },
        {
            "source": {
            "id": null,
            "name": "Barron's"
            },
            "author": "Al Root",
            "title": "GE Is Building the Electricity Grid of the Future. Here’s What We Found on a Tour. - Barron's",
            "description": "GE has some impressive history, and current technology, on display at its research center in upstate New York.",
            "url": "https://www.barrons.com/articles/ge-electricity-grid-future-51659730367",
            "urlToImage": imgs.news2,
            "publishedAt": "2024-04-21T12:00:00Z",
            "content": "NISKAYUNA, N. YIn \r\nGeneral Electric\r\n s\r\n sprawling research center here, you will find two Nobel Prizes won by \r\nGE\r\nscientists, Thomas Edisons desk, and current research that could help create the… [+5256 chars]"
        },
        {
            "source": {
            "id": "the-times-of-india",
            "name": "The Times of India"
            },
            "author": "Manju V",
            "title": "Akasa Air: India's newest airline debuts with Mumbai-Ahmedabad flight - Times of India",
            "description": "India Business News: MUMBAI: Billionaire Rakesh Jhunjhunwala's Akasa Air made its debut on Sunday with its first flight operating from Mumbai’s Chhatrapati Shivaji Maharaj.",
            "url": "https://timesofindia.indiatimes.com/business/india-business/akasa-air-indias-newest-airline-debuts-with-mumbai-ahmedabad-flight/articleshow/93410284.cms",
            "urlToImage": "https://static.toiimg.com/thumb/msid-93410283,width-1070,height-580,imgsize-49964,resizemode-75,overlay-toi_sw,pt-32,y_pad-40/photo.jpg",
            "publishedAt": "2024-04-27T11:52:00Z",
            "content": ""
        }
    ]
}
import React, { useState } from 'react';
import './Modal.scss';


function Modal (props) {
  let 
    [isForm, setIsForm] = useState('hidden'),
    [buttonText, setButtonText] = useState(''),
    [actionText, setActionText] = useState('Login'),
    [isButtonHidden, setIsButtonHidden] = useState(''),
    [hasText, setHasText] = useState('Already have an Account?'),
    [passwordInput, setPasswordInput] = useState("password");


  let [username, setUsername] = useState()
  let [email, setEmail] = useState()
  let [password, setPassword] = useState()

  let handleClick = (state) => {
    setIsButtonHidden('hidden')
    setIsForm('model-show')
    let a = state === 'Sign Up' ? 
      (setButtonText('Create an Account'), setActionText('Login'), setHasText('Already have an Account?')) : 
      (setButtonText('Login'), setActionText('Sign Up'), setHasText('Create new Account.'))
  }

  let onInputChange = (e, stateKey) => {
    const targetValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    switch (stateKey) {
      case "email":
        setEmail(targetValue)
        break;
      case "password":
        setPassword(targetValue)
        break;
      case "username":
        setUsername(targetValue)
        break;
      default:
        return targetValue
    }
  }

  let submit = (state) => {
    props.getData({username, email, password, state})
  }


  return (
    <React.Fragment>
      <div id='overlayModal' className="modal primary-modal" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className='modal-content'>
            <div className='modal-header f-f'>
              {props.headerTitle}
              <span className="close" data-dismiss="modal" onClick={() => props.cancel(false)}>&times;</span>
            </div>

            <div className='modal-body'>
              {/* {props.message} <br/> */}

              {/* login form :: start */}
              <div className={`col-md-6 col-xs-12 ${isForm}`}>
                {
                  buttonText !== 'Login' ?
                    <div className="form-group mrgin-top-25">
                    <input 
                        type="text" 
                        className="form-control m-t-10" 
                        placeholder="Enter your username"
                        defaultValue={username}
                        onChange={(e) => onInputChange(e, 'username')}
                    />
                  </div>
                  : null
                }

                <div className="form-group mrgin-top-25">
                    <input 
                        type="email" 
                        className="form-control m-t-10" 
                        placeholder="Enter your email"
                        defaultValue={email}
                        onChange={(e) => onInputChange(e, 'email')}
                    />
                </div>
                <div className="form-group mrgin-top-25">
                  <div style={{position: "relative"}}>
                    <input 
                        type={passwordInput} 
                        className="form-control m-t-10" 
                        placeholder="Enter your password"
                        defaultValue={password}
                        onChange={(e) => onInputChange(e, 'password')}
                    />
                    <span style={{position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer"}}>
                      <i onClick={() => setPasswordInput(passwordInput === 'password' ? 'text' : 'password')} className={`far ${passwordInput === 'password'?'fa-eye-slash':'fa-eye'}`}></i>
                    </span>
                  </div>
                </div>
                {/* <button className="btn btn-md bg-gradient-primary mb-0 me-1" onClick={() => submit(buttonText)}> */}
                <button className='btn btn-md signup-button-text mb-0 me-1'  onClick={() => submit(buttonText)}>
                    {buttonText}
                </button>
              </div>
              {/* login form :: end */}

              <button 
                onClick={(e) => handleClick('Sign Up')} 
                className={`btn btn-sm btn-outline-info text-nowrap mb-0 me-1 ${isButtonHidden}`} 
              >
                Create an account with email
              </button>

              <p className="mt-3">
                {hasText}&nbsp;
                <a 
                  role="button"
                  className="text-primary text-gradient font-weight-bold"
                  onClick={() => handleClick(actionText)} 
                > 
                  {actionText}
                </a>
              </p>
            </div>

            <div className='modal-footer'>
              {props.footerContent}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in"></div>
    </React.Fragment>
  );
}


export default Modal;

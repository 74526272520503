// Micro Blog
import React, { Fragment } from "react"

export const MicroBlog = React.memo(({ blog, index }) => (
    <Fragment key={index}>
      {index <= 2 && (
        <div className="col-lg-3 col-md-3 col-sm-3">
          <div className="card card-plain card-blog">
            <div className="card-image border-radius-lg position-relative">
              <img className="w-100 border-radius-lg move-on-hover shadow" src={blog.urlToImage} style={{minHeight: "22vh"}} alt={`blog-${index}`} />
            </div>
            <div className="card-body px-0">
              <p className='fs-14'>{blog.description.split(".")[0].split(",")[0]}</p>
              <span className="text-info icon-move-right">Read More&nbsp;
                <i className="fas fa-arrow-right text-sm"></i>
              </span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  ));
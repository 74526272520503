import React, { useState, useEffect, Fragment } from 'react';
import { PublicLayout } from '../../Layouts/PublicLayout';
import Modal from '../../Components/Common/Modal';
import * as imgs from "../../Assets/img";
import { toastError, toastSuccess  } from "../../Components/Common";
import "./Home.scss";
import newBlog from "../../Utils/newBlog"
import newFeed from '../../Utils/newFeed';
import { Link, useNavigate } from "react-router-dom"
import { 
  useSignupMutation, 
  useLoginMutation,
  useGetTrendingStoriesMutation
 } from '../../Service/services'
import { Helmet } from "react-helmet";
import { dateWithoutDay, dateFormatProfessional } from "../../Utils/helper" 
import { BsLinkedin, BsInstagram } from "react-icons/bs";
import { FaFacebookSquare, FaTelegram } from "react-icons/fa";
import { MicroTag } from './MicroTag';
import { MicroBlog } from './MicroBlog';


export default function Home() {
  let [isConfirmationModal, setConfirmationModal] = useState(false)
  let hasJoin = localStorage.getItem('user-data') !== null ? JSON.parse(localStorage.getItem('user-data')) : null
  let newFeeds = newFeed()
  let newBlogs =  newBlog()
  let navigate = useNavigate()
  let [signupAccount] = useSignupMutation()
  let [loginAccount] = useLoginMutation()
  let [trendingStoriesRender] = useGetTrendingStoriesMutation()

  let [trendingStories, setTrendingStories] = useState([])
  let [tags, setTags] = useState(["Product Development", "Software", "Technology", "Artificial Intelligence", "Designing", "Relationship", "New Innovation", "Machine Learning"])


  useEffect(() => {
    trendingStoriesRender()
    .then((success) => {
      if(success.data.statusCode === 200) {
        setTrendingStories(success.data.data)
      }
    })
    .catch((error) => {
      console.error(error)
    })
  },[])

    let handleLoginSuccess = () => {
      handleModalOk()
      window.location.reload()
      // navigate('/dashboard/account')
    };

    let handleSignupSuccess = () => {
      handleModalOk()
    };

    // login - sign up call
    let getData = (data) => {
      if(data.state === 'Create an Account') {
        if((data.username || data.email || data.password) === undefined) {
          return toastError("Please enter all fields.")
        }
        signupAccount({
          username: data.username,
          email: data.email,
          password: data.password,
          image: '',
          type: "users"
        })
        .then((success) => {
          toastSuccess("Signup successfull")
          handleSignupSuccess()
        })
        .catch((error) => {
          console.log('signup error :: ', error)
        })
      } else if(data.state === 'Login') {
        if((data.email || data.password) === undefined) {
          return toastError("Please enter email & password.")
        }
        loginAccount({
          email: data.email,
          password: data.password
        })
        .then((success) => {
          if(success.error !== undefined) {
            return toastError(success.error.data.message)
          }
          document.cookie = "isLogin=true"
          localStorage.setItem('user-data', JSON.stringify(success));
          toastSuccess("Login successfull")
          handleLoginSuccess()
        })
        .catch((error) => {
          console.log('signup error :: ', error)
          toastError('Login failed')
        })
      } 
    }

    let handleModal = () => {
      return (
        hasJoin !== null ? hasJoin?.data?.status === true ?
        navigate("/stories") :
        setConfirmationModal(true) :
        setConfirmationModal(true)
      )
    }

    let handleModalOk = () => setConfirmationModal(false)

    // new feed
    let handleNewFeed = (row, index) => {
      if(index > 3) return 
      return (
        <Fragment key={index}>
          <div className="col-lg-6 col-md-6 mt-4">
            <div className="card card-profile overflow-hidden card-box-shadow-none">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <div className="">
                    <img className="w-100 blog-images" src={row.urlToImage} alt={`blog-image-${index}`} /> 
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 my-auto">
                  <div className="card-body blog-card mt-2">
                    <span className='fs-7'>{dateFormatProfessional(row.publishedAt)} &nbsp; | &nbsp; <span>{row.author}</span></span><br/>
                    <span className="mb-0 fw-bold color-black">{row.title.split(".")[0]}</span>
                    <p className="mb-0 fs-7">{row.description.split(".")[0]}</p>
                    <span className='fs-7 mt-3' style={{backgroundColor: "#F1F2F3", color: "#242424", padding: "5px"}}>News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }

    let handleTrendingStories = (row, index) => {
      return (
        <div className="col-md-4 py-2" key={index}>
          <div className="d-flex">
            <div className="text-bold tending-index-number">
              0{index + 1}
            </div>
            <div className="info ps-3">
              <div className="">
                <img src={imgs.trending_user} alt={`tremding_icon_${index}`} height={20} width={20} color='#242424' />&nbsp;
                <span className='text-xs text-bold' style={{color: "#242424"}}>{row.userId.first_name} {row?.userId?.last_name}</span>
              </div>
              <Link to={`/stories/${row.userId.username}/${row.slug}`}>
                <span className='text-bold' style={{color: "#242424"}}>{row.title}</span>
              </Link>
              <p className='text-xs' style={{color: "#242424"}}>{dateWithoutDay(row.createdAt)} | {row.readTime} min read</p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <Fragment>
          <PublicLayout>
              <Helmet>
                  <meta charSet="utf-8"/>
                  <title>Revermonk | A decentralized community platform based on Web3</title>
                  <meta
                    name="title"
                    content="Revermonk | A decentralized community platform based on Web3"
                  />
                  <meta
                    name="description"
                    content="Revermonk | Empower your community with the latest advancements in Web3.0 technology. Build a thriving online space where members can connect, collaborate, and share ideas in a secure and transparent environment. Revermonk fosters a decentralized governance model, giving your community complete control over its operations. Additionally, Revermonk leverages blockchain technology to ensure immutability and tamper-proof data storage."
                  />
                  <meta name="keywords" content="decentralized based community, blockchain, build your own community, web3 community, revermonk, revermonk community, web3.0 community, decentralized community, community building, the community building, open source community, best open source community" />
                  <meta name="prerender-status-code" content="404" />
                  <link rel="canonical" href="https://Revermonk.com" />
              </Helmet>

              {/* header :: start new */}
                <header style={{backgroundColor: "#4c1896"}}>
                  <div className="page-header min-vh-50">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex justify-content-center flex-column" itemScope itemType ="https://schema.org/Home">
                          <h1 className="mb-4 fs-100 heading-title" itemProp="name">
                            Connecting people to build a better&nbsp;
                            <span className="relative">
                              <svg className="absolute left-0 right-0 bottom-0 transform scale-x-110 w-full" style={{height:"6px", maxHeight:"6px"}} preserveAspectRatio="none" viewBox="0 0 138.2 6.4" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M137.7 6.1c.2-.1.4-.4.5-.7s0-.7-.1-.9c-.3-.4-.7-.6-1-.7-.2 0-.5-.1-.9-.1-.4-.1-1-.1-1.6-.2-1.3-.1-3.1-.3-5.1-.5-3.9-.4-8.7-.8-12.5-1.1-4.1-.3-11.4-.8-15.8-1.1C96.9.6 83.4.1 79 .1 54.4-.2 30.2.6 13.8 2.3c-2.4.3-5.7.7-8.3 1-1.3.2-2.5.4-3.3.6-.5 0-.8.1-1.1.2-.1 0-.2 0-.3.1 0 0-.1 0-.2.1l-.2.2c-.1-.1-.4.2-.4.6 0 .7.5 1 .7 1 .2.1.4.1.5.1h1c.9 0 2.3-.2 4.4-.5 4.9-.6 8.6-1 13.2-1.3 20.6-1.6 51.1-2.1 73.6-1.1 12.5.6 25 1.4 37.3 2.7 2 .2 4.1.4 4.8.4M6.5 5.2l.1.5z" fill="#FF3B85" fillRule="evenodd"></path></svg>
                              future.
                            </span>

                          </h1>
                          <p className="heading-subtitle">
                            Revermonk is the easiest way to connect with people from all over the world and work together to create & build a better workplace.
                          </p>
                          <p className="heading-subtitle">
                            Join us today and start making a difference!
                          </p>
                          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <span className='btn btn-md heading-button-text' onClick={() => handleModal()}>
                                  Let's start writing
                              </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 d-flex justify-content-center flex-column">
                          <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}>
                            <img 
                              className="" 
                              src={imgs.office__dark2} 
                              alt="banner_image" 
                            />
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </header>
              {/* header :: end new */}


              {/* trending stories :: start */}
              <section className="py-4">
                <div className="container">
                  <div className="row align-items-center">

                    {/* heading : start */}
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row justify-content-start mb-4">
                        <h6>
                          <img src={imgs.trending} alt="trending-icon" height={20} width={20} />&nbsp;
                          Trending on&nbsp;
                          <span>
                            Revermonk
                          </span>
                        </h6>
                      </div>
                    </div>
                    {/* heading: end */}

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row justify-content-start">
                        {
                          trendingStories.length > 0 && trendingStories.map((row, index) => handleTrendingStories(row, index))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </section>
              {/* trending stories :: end */}


              {/* stories section :: start */}
              <section className="py-4" style={{backgroundColor: "#fafafa"}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className='col-md-7 col-sm-7 col-xs-12'>
                   
                      <img src={imgs.home_discovery} className='tending-section-image' alt="home_discovery" />

                    </div>
                    <div className='col-md-5 col-sm-5 col-xs-12'>

                      {/* heading : start */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row justify-content-start mb-4">
                          <h6>
                            Discover more of what matters to you
                          </h6>
                        </div>
                      </div>
                      {/* heading: end */}

                      {/* tags listing */}
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {tags.length > 0 && tags.map((name, index) => <MicroTag key={index} name={name} />)}
                      </div>

                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <hr/>
                        <Link to={"/tags"}><span className='fs-14 see-more-topic'>See more topics</span></Link>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
              {/* stories section :: end */}
              

              {/* new section :: start */}
              {/* <section className="py-4">
                <div className="container">
                  <div className="row align-items-center">

                    <div className="col-lg-12">
                      <h1 
                        className="hero_title__HaAVy" 
                        aria-label="Develop. Preview. Ship."
                      >
                        <span 
                          className="animated-gradient-text_background__OCnk0 animated-gradient-text_background-1__c2kfC" 
                          style={{content:'Develop.', padding:'0.3em', backgroundImage: "linear-gradient(to right, #4880EC, #019CAD)"}}
                        >
                          <span className='themeColorWhite'>
                            Leadership
                          </span>
                        </span>
                        <span 
                          className="animated-gradient-text_background__OCnk0 animated-gradient-text_background-2__OQEIi"
                          style={{content:'Preview.', padding:'0.3em', backgroundImage: "linear-gradient(to right, #7928CA, #FF0080)"}}
                        >
                          <span className='themeColorWhite'>
                            Adaptability
                          </span>
                        </span>
                        <span 
                          className="animated-gradient-text_background__OCnk0 animated-gradient-text_background-3__iyrbU" 
                          style={{content:'Develop.', padding:'0.3em', backgroundImage: "linear-gradient(to right, #FF4D4D, #F9CB28)"}}
                        >
                            <span className='themeColorWhite'>
                              Cooperation
                            </span>
                        </span>
                      </h1>
                    </div>

                  </div>
                </div>
              </section> */}
              {/* new section :: end */}

              {/* team :: start */}
              <section className=" pb-5 position-relative" style={{backgroundColor: "#5B8FFC"}}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 text-start mt-5">
                      <div className='row'>
                        {newFeeds.length > 0 &&
                          newFeeds.map((row, i) => handleNewFeed(row, i))
                        }
                      </div>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-5">
                        <div className='text-center'>
                            <span className="text-white z-index-1 fs-1 fw-bold">
                                Explore the latest updates and stories on our&nbsp;
                                <span className="relative footer-header-text">
                                    <svg className="absolute left-0 right-0 bottom-0 transform scale-x-110 w-full" style={{height:"6px", maxHeight:"6px"}} preserveAspectRatio="none" viewBox="0 0 138.2 6.4" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M137.7 6.1c.2-.1.4-.4.5-.7s0-.7-.1-.9c-.3-.4-.7-.6-1-.7-.2 0-.5-.1-.9-.1-.4-.1-1-.1-1.6-.2-1.3-.1-3.1-.3-5.1-.5-3.9-.4-8.7-.8-12.5-1.1-4.1-.3-11.4-.8-15.8-1.1C96.9.6 83.4.1 79 .1 54.4-.2 30.2.6 13.8 2.3c-2.4.3-5.7.7-8.3 1-1.3.2-2.5.4-3.3.6-.5 0-.8.1-1.1.2-.1 0-.2 0-.3.1 0 0-.1 0-.2.1l-.2.2c-.1-.1-.4.2-.4.6 0 .7.5 1 .7 1 .2.1.4.1.5.1h1c.9 0 2.3-.2 4.4-.5 4.9-.6 8.6-1 13.2-1.3 20.6-1.6 51.1-2.1 73.6-1.1 12.5.6 25 1.4 37.3 2.7 2 .2 4.1.4 4.8.4M6.5 5.2l.1.5z" fill="#F89F38" fillRule="evenodd"></path></svg>
                                    platform.
                                </span>
                            </span>
                        </div>
                    </div>

                  </div>
                </div>
              </section>
            {/* team :: end */}

            {/* count :: start */}
              <section className="pt-2 bg-gray-100" id="count-stats">
                <div className="container">
                  <div className="row justify-content-center text-center py-5">
                    <div className="col-md-3">
                    <h1 className="text-gradient text-info"><span id="state2" countto="5234">10</span>+</h1>
                      <h5>Writers</h5>
                      <p>of “high-performing” story posted</p>
                    </div>
                    <div className="col-md-3">
                      <h1 className="text-gradient text-info"><span id="state2" countto="3400">20</span>+</h1>
                      <h5>Hours</h5>
                      <p>Engagement of users to read stories</p>
                    </div>
                    <div className="col-md-3">
                      <h1 className="text-gradient text-info"><span id="state3" countto="24">24</span>/7</h1>
                      <h5>Support</h5>
                      <p>Actively engage team members that finishes on time</p>
                    </div>
                  </div>
                </div>
              </section> 
            {/* count :: end */}

            {/* new section :: start */}
            
            {/* new section :: end */}

            {/* recent blog :: start */}
            <section className="py-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="mb-5">
                      Check latest blogs
                    </h3>
                  </div>
                </div>
                <div className="row">
                  {/* newblogs */}
                  {newBlogs.length > 0 &&
                    newBlogs.map((blog, i) => <MicroBlog key={i} blog={blog} index={i} />)
                  }

                  <div className="col-lg-3 col-md-12 col-12">
                    <div className="card card-blog card-background box-shadow-none">
                      <div className="full-background"></div>
                      <div className="card-body pdt-1">
                        <div className="content-left text-start my-auto py-4">
                          <a role="button">
                            <h3 className="card-title text-white">Improved Collaboration</h3>
                            <p className="card-description text-white">
                              Revermonk can provide a platform for people to collaborate on projects and share ideas, 
                              This can be beneficial for businesses, entrepreneurs and other professionals who are looking to increase their reach and visibility.
                            </p>
                            <a role="button" className="text-white icon-move-right">Read More
                              <i className="fas fa-arrow-right text-sm"></i>
                            </a>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* recent blog :: end */}

            {/* open source :: start */}
            <section className="py-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 mt-3">

                    <div className="card card-blog card-background box-shadow-none opensource-card">
                      <div className="card-body pdt-3" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%", backgroundColor: "#FFC543", borderRadius: "1rem"}}>
                        <div style={{marginBottom: "4rem"}}>
                          <h4 className="card-title" style={{color: "#222"}}>Open-Source with limited resources</h4>
                          <p className='fs-13' style={{height: "50px", color: "#222"}}>Join our fast-growing community of open-source developers on <strong><u>GitHub</u></strong></p>
                        </div>
                        <img src="https://ice.io/wp-content/uploads/2022/11/github.png" alt="github" style={{alignSelf: "center"}} />
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mt-3">

                    {/* first */}
                    <div className="card card-blog card-background box-shadow-none opensource-card mb-3 flex-row">
                      <div className="card-body pdt-3" style={{ backgroundColor: "rgb(63 91 249)", display: "flex", borderRadius: "1rem" }}>
                        <div className="text" style={{ flex: "50%" }}>
                          <span className="card-title text-white lead text-bold">Twitter</span>
                          <p className="text-white fs-13">
                            Stay up to date with our latest <br/> tweets and join our community <br/> on <strong><u>Twitter</u></strong>
                          </p>
                        </div>
                        <div className="image">
                          <img src={imgs.twitter} alt="twitter" style={{ height: "8rem", width: "100%" }} />
                        </div>
                      </div>
                    </div>


                    {/* second */}
                    <div className="card card-blog card-background box-shadow-none opensource-card flex-row">
                      <div className="card-body pdt-3" style={{backgroundColor: "rebeccapurple", justifyContent: "space-between", borderRadius: "1rem"}}>
                        <div className="">
                          
                            <h4 className="card-title text-white" style={{color: "#222"}}>Join us on</h4>
                            <p>
                              <BsLinkedin color='#FFFFFF' className='mt-4' size={40} style={{marginRight: "2rem"}} />
                              <FaFacebookSquare color='#FFFFFF' className='mt-4' size={44} style={{marginRight: "2rem"}} />
                              <BsInstagram color='#FFFFFF' className='mt-4' size={44} style={{marginRight: "2rem"}} />
                              <FaTelegram color='#FFFFFF' className='mt-4' size={44} style={{marginRight: "2rem"}} />

                            </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>
            {/* open source :: end */}

          </PublicLayout>

        {isConfirmationModal ? (
            <Modal
              headerTitle={`Be a Part of Something Bigger: Join Our Community and Explore New Ideas.`}
              footerContent={`Click “Sign Up” to agree to Revermonk Terms of Service and acknowledge that Revermonk Privacy Policy applies to you.`}
              getData={getData}
              cancel={setConfirmationModal}
            />
        ) : null }
      </Fragment>
    )
}

import moment from "moment";
import Resizer from "react-image-file-resizer";

export function dateWithoutDay(date) {
    // return moment(date).utcOffset("+05:30").format('MMMM D, YYYY, h:mm a')
    return moment(date).utcOffset("+05:30").format('MMM D')
}

export default function date(date) {
    return moment(date).utcOffset("+05:30").format('LLLL')
}

export function titleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringSpace(string) {
    let s = ''
    s = string.split(" ")

    return s
    
}

export function dateFormat(date) {
    return moment(date).utcOffset("+05:30").format('MMMM Do YYYY')
}

export function dateFormatProfessional(date) {
    return moment(date).utcOffset("+05:30").format('MMM D, YYYY')
}

export function shortDescription(description, length, link) {
    let more = link !== "" ? `<a src=${link} className=""> Read More</a>` : ""
    if (description.length > length) {
        return description.slice(0, length) + '...'+ more;
    } else {
        return description + more;
    }
}

import React, { useState } from "react";
import { Footer } from "../../Components/Common";
import PublicHeader from "../../Components/Common/Header/publicHeader_v2";


function PublicLayout({ children }) {
  const [isSideBarVisible, setSidebarVisbibility] = useState(false);

  return (
    <React.Fragment>
      <PublicHeader isPublic={true} onSidebarToggle={() => setSidebarVisbibility(!isSideBarVisible)} />
      {children}
      <Footer />
    </React.Fragment>
  );
}

export default PublicLayout;
